<script lang="ts" setup>
  import { BaseButton } from '@bt/design-system'
  import type Link from '@/types/Storyblok/Generic/Link'

  const { locale } = useI18n()

  const { blok } = defineProps<{
    blok: any
  }>()

  const getPath = (href: Link) => {
    if (href.story) {
      return formatSbPath(href.story.full_slug, locale.value)
    }

    if (href.url) {
      return href.url
    }
  }
</script>

<template>
  <NuxtLink
    :to="getPath(blok.href)"
    :target="blok.target"
  >
    <BaseButton
      :is-block="true"
      :target="blok.target"
      :title="blok.title"
      block-type="full"
      color="ghost"
      density="default"
    />
  </NuxtLink>
</template>
